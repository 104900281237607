<template>
  <div v-if="isShow" class="c-modal">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__body">
        マニフェストを取り消します 。<br />
        よろしいですか？
      </div>
      <div class="c-modal__foot">
        <div class="c-btn secondary small" @click="clickCloseHandler">戻る</div>
        <div class="c-btn primary small" @click="clickDeleteOkHandler">
          決定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
    },
    isDetailModalShow: {
      type: Boolean,
    },
  },
  methods: {
    clickCloseHandler() {
      this.$emit("update:isShow", false);
    },
    clickDeleteOkHandler() {
      // call API
      this.$emit("delete");
      this.$emit("update:isShow", false);
      this.$emit("update:isDetailModalShow", false);
    },
  },
};
</script>
