import axios from "axios";
import { API_URL, DOMAIN } from "@/const";

export default {
  data() {
    return {};
  },
  methods: {
    // スケジュール押下後にAPI呼び出して詳細モーダル展開  API_URL.
    clickScheduleHandler(manifestId) {
      axios
        .get(API_URL.SCHEDULE.DETAIL + manifestId)
        .then((res) => {
          this.selectedSchedule = res.data;
        })
        .then(() => {
          this.isDetailModalShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // deleteモーダル内の「決定」押下   API_URL.MANIFEST.STATUS_UPDATE
    clickDeleteHandler() {
      const data = {
        manifestIdList: [ { manifestId: this.selectedSchedule.manifestInfo.manifestId } ],
        manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE
      };
      axios
        .patch(API_URL.MANIFEST.STATUS_UPDATE, data)
        .then(() => {
          this.isDetailModalShow = false;
          //リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
