<template>
  <div class="c-doc">
    <div class="c-doc__title">{{ title }}</div>
    <div class="c-doc__body">
      <div
        class="c-doc__body__inner"
        v-for="(contents, index) in bodyContent"
        :key="index"
      >
        <span class="c-doc__body__count">{{ contents.count }}</span>
        {{ contents.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    bodyContent: {
      type: Array,
    },
  },
};
</script>
