<template>
  <div class="l-container">
    <div class="l-each">
      <div class="l-lead-s">
        <h3>未開放のアカウント</h3>
      </div>
      <div class="l-progress">
        <div class="c-progress__category emit">
          <div>排出業者</div>
        </div>
        <div class="c-progress__line"><span></span></div>
        <Progress
          progressType="before"
          title="委託契約前"
          progressStr="契約登録"
          :count="contractRegistCount.notEntrustCount"
          :linkTo="`/generator/contract?status=${domain.GENERATE_CONTRACT_SEARCH_STATUS.NOT_ENTRUST}`"
        />
        <Progress
          progressType="prepare"
          title="準備中"
          progressStr="開放日未設定"
          :count="contractRegistCount.notAccountStartDateCount"
          :linkTo="`/generator/contract?status=${domain.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_NOTSETTING}`"
        />
        <Progress
          progressType="done"
          title="準備完了"
          progressStr="開放日予約済"
          :count="contractRegistCount.reservedAccountStartDateCount"
          :linkTo="`/generator/contract?status=${domain.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_RESERVED}`"
        />
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s"></div>
      <div class="l-progress">
        <div class="c-progress__category despose">
          <div>運搬事業者</div>
        </div>
        <div class="c-progress__line double"><span></span></div>
        <Progress
            progressType="prepare"
            title="準備中"
            progressStr="開放日未設定"
            :count="collectorRegistCount.notAccountStartDateCount"
            linkTo="/operate?mailNotSetting=true"
        />
        <Progress
            progressType="done"
            title="準備完了"
            progressStr="開放日予約済"
            :count="collectorRegistCount.reservedAccountStartDateCount"
            :linkTo="`/operate?accountStartDateFrom=${tomorrow.format('YYYY-MM-DD')}`"
        />
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s">
        <h3>期限切れが近い書類</h3>
      </div>
      <div class="l-col3">
        <router-link
          :to="`/licence?licenceStatusList=${domain.LICENCE_STATUS.WARNING},${domain.LICENCE_STATUS.EXPIRED}`"
          class="c-doc link licence"
          :class="{
            warning:
              licenceExpiredCount.expiredAlertCount &&
              !licenceExpiredCount.expiredCount,
            alert: licenceExpiredCount.expiredCount,
          }"
        >
          <div class="c-doc__title">許可証</div>
          <div class="c-doc__body">
            <div class="c-doc__body__inner">
              <span class="c-doc__body__count">{{ licenceExpiredCount.expiredAlertCount + licenceExpiredCount.expiredCount }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s l-flex">
        <div class="l-flex">
          <h3>廃棄物排出状況</h3>
          <span class="c-lead-s l-area-side">
            {{ fromDate | dateJpYMDFormat }}
            〜
            {{ toDate | dateJpYMDFormat }}
          </span>
        </div>
        <div class="l-flex__right">
          <div class="c-tabs">
            <div
              class="c-tabs__each"
              :class="{ active: isMonthShow }"
              @click="changeDisplay('month')"
            >
              月
            </div>
            <div
              class="c-tabs__each"
              :class="{ active: !isMonthShow }"
              @click="changeDisplay('week')"
            >
              週
            </div>
          </div>
        </div>
      </div>

      <div class="l-contents">
        <div class="l-flex wrap">
          <div
            v-for="item in totalizationJwnetItem"
            class="c-item"
            :key="'total-jwnet-' + item.id"
          >
            <span class="c-item__name">{{ item.name }}</span>
            <span class="c-item__summary">{{ item['summary'] | formatFloat }}</span>
            <span class="c-item__unit">t</span>
          </div>
          <div v-if="!totalizationJwnetItem.length" class="c-item">
            当期間の集計データがありません。
          </div>
        </div>
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s">
        <h3>サービス登録状況</h3>
      </div>
      <div class="l-col3">
        <Service
          title="排出事業者"
          :bodyContent="[
            {
              count: totalizationRegistCount.generatorContractCount,
              label: '契約',
            },
          ]"
        />
        <Service
          title="運搬事業者"
          :bodyContent="[
            { count: totalizationRegistCount.collectorCount, label: '登録' },
            {
              count: totalizationRegistCount.collectorContractCount,
              label: '契約',
            },
          ]"
        />
        <Service
          title="処分事業者"
          :bodyContent="[
            { count: totalizationRegistCount.disposerCount, label: '登録' },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Progress from "./Progress.vue";
import Service from "./Service.vue";
import callApi from "../mixins/callApi";
import {DOMAIN} from "@/const";

export default {
  components: {
    Progress,
    Service,
  },
  mixins: [callApi],
  data() {
    return {
      isMonthShow: true,
      tomorrow: moment().add(1, 'days'),
      // API返り値
      contractRegistCount: {},
      collectorRegistCount: {},
      licenceExpiredCount: {},
      totalizationJwnetItem: [],
      totalizationRegistCount: {},
      domain: DOMAIN,
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
    };
  },
  methods: {
    changeDisplay(type) {
      this.fromDate = moment().startOf(type);
      this.toDate = moment().endOf(type);
      this.isMonthShow = type === "month";
      this.getTotalizationJwnetItemApi(type);
    },
  },
  mounted() {
    this.getCbaInitApi();
  },
};
</script>
