/**
 * 位置を計算してポップアップを表示
 * @param popupRef 
 * @param popupInnerRef 
 * @param popupTextRef 
 */
export const showPopup = (popupRef, popupInnerRef, popupTextRef) => {
  //マウスホバー時
  popupRef.addEventListener('mouseover', function () {
    // NOTE: 最初にチラつくので消しておく
    popupInnerRef.style.visibility = 'hidden';
    popupInnerRef.style.opacity = 0;

    // NOTE: 3点リーダーで省略されている場合のみツールチップ表示
    if (popupTextRef.offsetWidth < popupTextRef.scrollWidth) {
      const calendar__popup__body = popupRef.parentNode;
      
      // ポップアップの表示位置調整
      // NOTE: スクロール量考慮
      const scrollQuantity = calendar__popup__body.scrollTop;
      const top = -25 - scrollQuantity;
      popupInnerRef.style.transform = `translateY(${top}px)`;
      popupInnerRef.style.visibility = 'visible';
      popupInnerRef.style.opacity = 1;
    }
  });
  
  //マウスホバー解除時
  popupRef.addEventListener('mouseout', function () {
    popupInnerRef.style.visibility = 'hidden';
    popupInnerRef.style.opacity = 0;
  });
};