import moment from "moment";

export default {
  methods: {
    mergeFormatDateStr(date, timeNum) {
      return `${date} ${timeNum}`;
    },
    formatReserveDate(reserve) {
      if (reserve.collectionTime) {
        return `${reserve.collectionDate} ${reserve.collectionTime}`;
      } else {
        return `${reserve.collectionDate}`;
      }
    },
    isBetweenDate(startTime, reserveTime) {
      if (reserveTime === "終日") return false;

      const gap = this.isPerHour ? 59 : 29; // ちょうど60分or30分で加算すると、時間内の判定ができないため-１している
      const endTime = moment(startTime).add(gap, "minutes").format();
      return (
        moment(reserveTime).isSameOrAfter(startTime, "minute") &&
        moment(reserveTime).isSameOrBefore(endTime, "minute")
      );
    },
    isSameDate(date1, date2) {
      return (
        moment(date1).isSame(date2, "year") &&
        moment(date1).isSame(date2, "month") &&
        moment(date1).isSame(date2, "day")
      );
    },
  },
};
