<template>
  <router-link
    :to="getInfoLink"
    class="c-info list"
    :class="[
      infoType,
      {
        isRead: info.isRead,
      },
    ]"
  >
    <div class="c-info__cell date">{{ info.date | dateFormat }}</div>
    <div class="c-info__cell title">
      <span v-if="info.isImportant" class="c-badge">重要</span>
      <span class="c-info__cell__title">{{ info.title }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    infoType: {
      type: String,
    },
    info: {
      type: Object,
    },
  },
  computed: {
    getInfoLink() {
      return this.info.isRead === true
        ? `/information/${this.info.id}?isRead`
        : `/information/${this.info.id}`;
    },
  },
};
</script>
