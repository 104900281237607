<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">事業場絞り込み</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div>
          <div class="c-modal__body__originalList">
            <div class="c-checkbox" v-for="store in showStores" :key="store.id">
              <input
                :id="'store-sort-cb-' + store.id"
                type="checkbox"
                :value="store"
                v-model="selectedStores"
              />
              <label
                class="c-checkbox__label"
                :for="'store-sort-cb-' + store.id"
              >
                <span class="c-checkbox__box"></span>
                {{ dottedText(store.name, 23) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixin/common";
import { LOCAL_STORAGE_INFO } from "@/const";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    storeList: {
      type: Array,
    },
  },
  mixins: [common],
  data() {
    return {
      selectedStores: [],
    };
  },
  computed: {
    showStores() {
      let stores = [];
      this.storeList.map((company) => {
        company.generateStoreList.map((store) => {
          stores.push(store);
        });
      });
      return stores;
    },
  },
  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    clickSetHandler() {
      localStorage.setItem(
        LOCAL_STORAGE_INFO.USE_STORE_LIST,
        JSON.stringify(this.selectedStores)        
      );
      const selectedIds = this.selectedStores.map(uSl => {
        return uSl.id
      })
      localStorage.setItem(LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK, JSON.stringify(selectedIds))
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST]) {
          this.selectedStores = JSON.parse(localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST]);
        } else {
          this.showStores.forEach((item) => {
            if (this.selectedStores.length < 5) {
              this.selectedStores.push(item);
            }
          });
        }
      }
    },
  },
};
</script>
