<template>
  <div class="c-progress">
    <router-link :to="titleLinkTo">
      <span class="c-progress__title">{{ title }}</span>
    </router-link>
    <router-link :to="linkTo" class="c-progress__square">
      <div class="c-progress__square__inner">
        <span class="c-progress__square__str">{{ progressStr }}</span>
        <span class="c-progress__square__count">{{ count }}</span>
      </div>
      <span class="c-progress__square__arrow"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    progressStr: {
      type: String,
    },
    count: {
      type: Number,
    },
    linkTo: {
      type: String,
      default: "/",
    },
    titleLinkTo: {
      type: String,
      default: "/",
    },
  },
};
</script>
