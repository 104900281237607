var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"popup",staticClass:"c-popup"},[(_vm.reserve.proxyCollectMemberName != null || _vm.reserve.proxyDisposalMemberName != null)?_c('span',{staticClass:"c-reserve-proxy",class:[
      _vm.getStatusClass(_vm.reserve.generateStoreId),
      {
        needapproval: _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVE_PENDING,
        isdenied: _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVE_DENIAL,
        isapproved: _vm.reserve.manifestStatusId === _vm.manifestStatus.UNDER_RESERVE || _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVED || _vm.reserve.manifestStatusId === _vm.manifestStatus.UNDER_ISSUE,
        issued: _vm.reserve.manifestStatusId >= _vm.manifestStatus.ISSUED,
      } ]},[_vm._v(_vm._s(_vm.displayRegister()))]):_vm._e(),_c('div',{staticClass:"c-reserve",class:[
      _vm.getStatusClass(_vm.reserve.generateStoreId),
      {
        needapproval: _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVE_PENDING,
        isdenied: _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVE_DENIAL,
        isapproved: _vm.reserve.manifestStatusId === _vm.manifestStatus.UNDER_RESERVE || _vm.reserve.manifestStatusId === _vm.manifestStatus.RESERVED || _vm.reserve.manifestStatusId === _vm.manifestStatus.UNDER_ISSUE,
        issued: _vm.reserve.manifestStatusId >= _vm.manifestStatus.ISSUED,
      },
      _vm.displayIcon(_vm.reserve) ],on:{"click":_vm.clickHandler}},[_c('div',{style:([(this.reserve.proxyCollectMemberName != null && this.reserve.isRecurring) ?  { 'width': '100%' } : {'width': '93%'} ])},[_c('div',{staticClass:"c-reserve__text"},[_c('span',[_vm._v(_vm._s(_vm.reserve.collectionTime)+" "+_vm._s(_vm.reserve.jwnetItemTypeName))])]),_c('div',{ref:"popup_text",staticClass:"c-reserve__text"},[_c('span',[_vm._v(_vm._s(_vm.reserve.routeName))])])])]),_c('div',{ref:"popup__inner",staticClass:"c-popup__inner"},[_c('span',[_vm._v(_vm._s(_vm.reserve.routeName))])])])}
var staticRenderFns = []

export { render, staticRenderFns }