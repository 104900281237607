import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {};
  },
  methods: {
    // プロフィール情報取得API    API_URL.PROFILE.PROFILE
    getProfileApi() {
      return axios
        .get(API_URL.PROFILE.PROFILE)
        .then((res) => {
          this.profile = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //排出業者ホーム情報取得API    API_URL.HOME.GENERATEHOME
    getGenerateHomeApi() {
      return axios
        .get(API_URL.HOME.GENERATEHOME)
        .then((res) => {
          this.generateHome = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //処分業者ホーム情報取得API    API_URL.HOME.DISPOSALHOME
    getDisposalHomeApi() {
      return axios
        .get(API_URL.HOME.DISPOSALHOME)
        .then((res) => {
          this.disposalHome = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 排出契約登録状況別件数取得API    API_URL.GENERATOR.CONTRACT_REGIST_COUNT
    getContractRegistCountApi() {
      return axios
        .get(API_URL.GENERATOR.CONTRACT_REGIST_COUNT)
        .then((res) => {
          this.contractRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬契約登録状況別件数取得API    API_URL.COLLECTOR.REGIST_COUNT
    getCollectorRegistCountApi() {
      return axios
        .get(API_URL.COLLECTOR.REGIST_COUNT)
        .then((res) => {
          this.collectorRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 許可証期限切れ件数取得API    API_URL.LICENCE.EXPIRED_COUNT
    getLicenceExpiredCountApi() {
      return axios
        .get(API_URL.LICENCE.EXPIRED_COUNT)
        .then((res) => {
          this.licenceExpiredCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類別数量集計取得API    API_URL.TOTALIZATION.JWNET_ITEM
    getTotalizationJwnetItemApi(type) {
      return axios
        .get(`${API_URL.TOTALIZATION.JWNET_ITEM}?type=${type}`)
        .then((res) => {
          this.totalizationJwnetItem = res.data.totalizationJwnetItemList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Wellfest利用件数取得    API_URL.TOTALIZATION.REGIST_COUNT
    getTotalizationRegistCountApi() {
      return axios
        .get(API_URL.TOTALIZATION.REGIST_COUNT)
        .then((res) => {
          this.totalizationRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 事業場選択肢取得API    API_URL.SCHEDULE.GENERATE_STORE
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          this.storeList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 初期で呼ぶAPIまとめ
    getCbaInitApi() {
      // 排出契約登録状況別件数取得API
      this.getContractRegistCountApi();

      // 運搬契約登録状況別件数取得API
      this.getCollectorRegistCountApi();

      // 許可証期限切れ件数取得API
      this.getLicenceExpiredCountApi();

      // 廃棄物種類別数量集計取得API
      this.getTotalizationJwnetItemApi('month');

      // Wellfest利用件数取得
      this.getTotalizationRegistCountApi();
    },
  },
};
