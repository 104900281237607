<template>
  <div class="l-col3">
    <router-link
      :to="linkTo"
      class="c-doc link"
      :class="[
        {
          warning: isWarning,
          alert: isAlert,
        },
        docType,
      ]"
    >
      <div class="c-doc__title">{{ title }}</div>
      <div class="c-doc__body">
        <div class="c-doc__body__inner">
          <span class="c-doc__body__count">{{ count }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    docType: {
      type: String,
    },
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    isWarning: {
      type: Boolean,
    },
    isAlert: {
      type: Boolean,
    },
    linkTo: {
      type: String,
    },
  },
};
</script>
