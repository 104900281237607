<template>
  <div class="l-container">
    <div class="l-each">
      <div class="l-lead-s">
        <h3>期限切れが近い書類</h3>
      </div>
      <div class="l-doc">
        <Doc
          title="契約書"
          :count="collectHome.entrustAlertInfo ? collectHome.entrustAlertInfo.count : 0"
          :isWarning="collectHome.entrustAlertInfo && collectHome.entrustAlertInfo.alertTypeId === 1"
          :isAlert="collectHome.entrustAlertInfo && collectHome.entrustAlertInfo.alertTypeId === 2"
          docType="contract"
          :linkTo="`/entrust?status=${domain.CONTRACT_SEARCH_STATUS.ALERT_ACTIVE},${domain.CONTRACT_SEARCH_STATUS.CLOSE_INVALID}`"
        />
        <Doc
          title="許可証"
          :count="collectHome.licenceAlertInfo ? collectHome.licenceAlertInfo.count : 0"
          :isWarning="collectHome.licenceAlertInfo && collectHome.licenceAlertInfo.alertTypeId === 1"
          :isAlert="collectHome.licenceAlertInfo && collectHome.licenceAlertInfo.alertTypeId === 2"
          docType="licence"
          :linkTo="`/licence?licenceStatusList=${domain.LICENCE_STATUS.WARNING},${domain.LICENCE_STATUS.EXPIRED}`"
        />
        <div class="l-col3"></div>
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s">
        <h3>排出スケジュール {{ today.year() }}年{{ today.month() + 1 }}月</h3>
      </div>
      <div
        class="l-container__wide"
        :class="{
          withSide: isSideShow,
        }"
      >
        <div class="l-calendar">
          <Calendar
            :useStoreList="useStoreList"
            :selectedStoreList="selectedStoreList"
          />
        </div>
      </div>
    </div>
    <SortStoreListModal
      :isShow.sync="isStoreListSortModalShow"
      :storeList="storeList"
    />
  </div>
</template>

<script>
import moment from "moment";
import Doc from "../Doc";
import Calendar from "../calendar/Calendar";
import SortStoreListModal from "../calendar/SortStoreListModal";
import callApi from "./mixins/callApi";
import { DOMAIN } from "@/const/index";

export default {
  components: {
    Calendar,
    Doc,
    SortStoreListModal,
  },
  mixins: [callApi],
  data() {
    return {
      isSideShow: true,
      isStoreListSortModalShow: false,
      today: moment(),
      startDateWeek: null,
      endDateWeek: null,
      inWeekDate: moment(this.today).add(7, "days"),
      // API返り値
      collectHome: {
        notIssueManifestAlertInfo: {
        count: 0,
        alertTypeId: 0
        },
        notReportManifestCount: 0,
        completeReportManifestCount: 0,
        thisWeekReportCount: 0,
        fixCheckCount: 0,
        manifestAlertInfo: {
          count: 0,
          alertTypeId: 0
        },
        entrustAlertInfo: {
          count: 0,
          alertTypeId: 0
        },
        licenceAlertInfo: {
          count: 0,
          alertTypeId: 0
        }
      },
      useStoreList: [],
      storeList: [],
      selectedStoreList: [],
      domain: DOMAIN,
    };
  },

  methods: {

    getStartDate() {
      let date = moment(this.currentDate);
      date.startOf("month");
      const dateNum = date.day();
      return date.subtract(dateNum, "days");
    },

    getEndDate() {
      let date = moment(this.currentDate);
      date.endOf("month");
      const dateNum = date.day();
      return date.add(6 - dateNum, "days");
    },

    getWeekDate() {
      let startDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);
      let calendars = [];

      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          weekRow.push({
            year: startDate.get("year"),
            month: startDate.get("month") + 1, // 月は0からカウントするため+1する
            date: startDate.get("date"),
            fullStr: `${startDate.format('YYYY-MM-DD')}`,
            full: startDate.toDate(),
          });
          startDate.add(1, "days");
        }
        calendars.push(weekRow);
      }
      const today = this.today.format('YYYY-MM-DD');
      for (let week of calendars) {
        for (let day of week) {
          if(day.fullStr === today) {
            this.startDateWeek = week[0].fullStr;
            this.endDateWeek = week[6].fullStr;
            return;
          }
        }
      }
    },

    toggleSideHandler() {
      this.isSideShow = !this.isSideShow;
    },

    // 事業場選択肢: 運搬では絞り込み要素がないため、全事業場を表示対象とする
    getUseStoreList() {
      this.storeList.map((list) => {
        list.generateStoreList.map((item) => {
          this.useStoreList.push(item)
        });
      });
    },
    // 事業場選択肢取得を選択状態にするため、
    initSelectedStoreList() {
      this.useStoreList.map((item) => {
        this.selectedStoreList = [...this.selectedStoreList, item.id];
      });
    },
  },
  mounted() {
    this.getWeekDate();
    this.getCollectHomeApi();
    // 事業場選択肢取得
    this.getStoreListApi()
      .then(() => {
        this.getUseStoreList();
      })
      .then(() => {
        this.initSelectedStoreList();
      });
  },
};
</script>
