var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-progress"},[_c('router-link',{attrs:{"to":_vm.titleLinkTo}},[_c('span',{staticClass:"c-progress__title"},[_vm._v(_vm._s(_vm.title))])]),_c('router-link',{staticClass:"c-progress__arrow",class:[
      {
        warning: _vm.isWarning,
        alert: _vm.isAlert,
      },
      _vm.progressType ],attrs:{"to":_vm.linkTo}},[_c('span',{staticClass:"c-progress__arrow__icon",class:_vm.progressType}),_c('div',{staticClass:"c-progress__arrow__inner",class:{
      adjustinner: _vm.count.toString().length >=4 && (_vm.isWarning || _vm.isAlert),
    }},[_c('span',{staticClass:"c-progress__arrow__str"},[_vm._v(_vm._s(_vm.progressStr))]),_c('span',{staticClass:"c-progress__arrow__count"},[_vm._v(_vm._s(_vm.count))])]),_c('span',{staticClass:"c-progress__arrow__arrow"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }