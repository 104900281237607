import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {};
  },
  methods: {
    // 事業場選択肢取得API    API_URL.SCHEDULE.GENERATE_STORE
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          this.storeList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類選択肢取得API
    getJwnetItemTypeApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemType = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // スケジュール一覧API
    getScheduleListApi(date) {
      return axios
        .get(`${API_URL.SCHEDULE.LIST}?targetYear=${date.year()}&targetMonth=${date.month() + 1}`)
        .then((res) => {
          this.schedules = [...this.schedules, ...res.data.scheduleList];
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
