<template>
  <div class="l-container">

    <div class="l-each">
      <div class="l-lead-s">
        <h3>期限切れが近い書類</h3>
      </div>
      <div class="l-doc">
        <Doc
          title="契約書"
          :count="disposalHome.entrustAlertInfo.count"
          :isWarning="disposalHome.entrustAlertInfo.alertTypeId === 1"
          :isAlert="disposalHome.entrustAlertInfo.alertTypeId === 2"
          docType="contract"
          :linkTo="`/entrust?status=${domain.CONTRACT_SEARCH_STATUS.ALERT_ACTIVE},${domain.CONTRACT_SEARCH_STATUS.CLOSE_INVALID}`"
        />
      </div>
    </div>

  </div>
</template>

<script>
import Doc from "./Doc";

import callApi from "../mixins/callApi";
import { DOMAIN } from "@/const";
export default {
  components: {
    Doc,
  },
  mixins: [callApi],
  data() {
    return {
      // API返り値
      disposalHome: {
        entrustAlertInfo: {
          count: 0,
          alertTypeId: 0
        },
      },
      domain: DOMAIN,
    };
  },
  mounted() {
    this.getDisposalHomeApi();
  },
};
</script>