<template>
  <div class="l-container">
    <div class="l-each">
      <div class="l-lead-s">
        <h3>マニフェストの状況</h3>
      </div>
      <div class="l-progress">
        <Progress
          progressType="before"
          title="交付前"
          progressStr="本日交付"
          :count="generateHome.todayIssueInfo ? generateHome.todayIssueInfo.count : 0"
          :isWarning="generateHome.todayIssueInfo ? generateHome.todayIssueInfo.alertTypeId === 1 : false"
          :isAlert="generateHome.todayIssueInfo ? generateHome.todayIssueInfo.alertTypeId === 2 : false"
          titleLinkTo="/manifests/reserve"
          :linkTo="`/manifests/reserve?collectionDateTo=${today.format('YYYY-MM-DD')}`"
        />
        <Progress
          progressType="prepare"
          title="進行中"
          progressStr="進捗確認"
          :count="generateHome.progressCheckCount"
          titleLinkTo="/manifests/progress"
          :linkTo="`/manifests/progress?registDateFrom=${beforeWeekDate.format('YYYY-MM-DD')}&registDateTo=${today.format('YYYY-MM-DD')}&termDateTypeList=${domain.TERM_DATE_TYPE.COLLECTION_DATE},${domain.TERM_DATE_TYPE.DISPOSAL_DATE},${domain.TERM_DATE_TYPE.FINAL_DISPOSAL_DATE}`"
        />
        <Progress
          progressType="done"
          title="完了"
          progressStr="直近完了"
          :count="generateHome.todayCompleteCount"
          titleLinkTo="/manifests/complete"
          :linkTo="`/manifests/complete?registDateFrom=${beforeWeekDate.format('YYYY-MM-DD')}&registDateTo=${today.format('YYYY-MM-DD')}&termDateTypeList=${domain.TERM_DATE_TYPE.COLLECTION_DATE},${domain.TERM_DATE_TYPE.DISPOSAL_DATE},${domain.TERM_DATE_TYPE.FINAL_DISPOSAL_DATE}`"
        />
        <ProgressEnd
          title="待機"
          progressStr="修正確認"
          :count="generateHome.fixCheckCount"
          titleLinkTo="/manifests/progress"
          :linkTo="`/manifests/progress?status=${domain.MANIFEST_SEARCH_TYPE.BEFORE_REPORT},${domain.MANIFEST_SEARCH_TYPE.MANIFEST_FIX_DENIAL},${domain.MANIFEST_SEARCH_TYPE.FIX_PENDING}`"
        />
        <ProgressEnd
            title="完了"
            progressStr="最終処分NG"
            :count="generateHome.finalDisposalSiteNGCount"
            titleLinkTo="/manifests/complete"
            :linkTo="`/manifests/complete?status=60`"
        />
      </div>
    </div>

    <div class="l-each" v-if="generateHome.manifestAlertInfo.count || generateHome.entrustAlertInfo.count || generateHome.licenceAlertInfo.count">
      <div class="l-lead-s">
        <h3>期限切れが近い書類</h3>
      </div>
      <div class="l-doc">
        <Doc v-if="generateHome.manifestAlertInfo.count"
          title="マニフェスト"
          :count="generateHome.manifestAlertInfo.count"
          :isWarning="generateHome.manifestAlertInfo.alertTypeId === 1"
          :isAlert="generateHome.manifestAlertInfo.alertTypeId === 2"
          docType="manifest"
          :linkTo="`/manifests/all?status=${domain.MANIFEST_SEARCH_TYPE.COLLECTION_DATE_ALERT}, ${domain.MANIFEST_SEARCH_TYPE.COLLECTION_DATE_EXPIRED}, ${domain.MANIFEST_SEARCH_TYPE.REPORT_DATE_ALERT}, ${domain.MANIFEST_SEARCH_TYPE.REPORT_DATE_EXPIRED}&isActive=true&transitionSourceHome=home`"
        />
        <Doc v-if="generateHome.entrustAlertInfo.count"
          title="契約書"
          :count="generateHome.entrustAlertInfo.count"
          :isWarning="generateHome.entrustAlertInfo.alertTypeId === 1"
          :isAlert="generateHome.entrustAlertInfo.alertTypeId === 2"
          docType="contract"
          :linkTo="`/entrust?status=${domain.CONTRACT_SEARCH_STATUS.ALERT_ACTIVE},${domain.CONTRACT_SEARCH_STATUS.CLOSE_INVALID}`"
        />
        <Doc v-if="generateHome.licenceAlertInfo.count"
          title="許可証"
          :count="generateHome.licenceAlertInfo.count"
          :isWarning="generateHome.licenceAlertInfo.alertTypeId === 1"
          :isAlert="generateHome.licenceAlertInfo.alertTypeId === 2"
          docType="licence"
          :linkTo="`/licence?licenceStatusList=${domain.LICENCE_STATUS.WARNING},${domain.LICENCE_STATUS.EXPIRED}&targetLicenceList=${domain.TARGET_LICENCE.ENTRUST}`"
        />
      </div>
    </div>

    <div class="l-each">
      <div class="l-lead-s">
        <h3>排出スケジュール {{ today.year() }}年{{ today.month() + 1 }}月</h3>
      </div>
      <div
        class="l-container__wide"
        :class="{
          withSide: isSideShow,
        }"
      >
        <div class="l-calendar">
          <Calendar
            :useStoreList="useStoreList"
            :selectedStoreList="selectedStoreList"
          />
        </div>
        <SortSideMenu
          v-if="isSideShow"
          :useStoreList.sync="useStoreList"
          :selectedStoreList.sync="selectedStoreList"
          :isStoreListSortModalShow.sync="isStoreListSortModalShow"
          :isSideShow.sync="isSideShow"
        />
        <div
          v-else
          class="c-toggle"
          :class="{
            isOpen: isSideShow,
          }"
        >
          <div class="c-toggle__btn" @click="isSideShow = !isSideShow"></div>
        </div>
      </div>
    </div>
    <SortStoreListModal
      :isShow.sync="isStoreListSortModalShow"
      :storeList="storeList"
    />
  </div>
</template>

<script>
import moment from "moment";
import Doc from "./Doc";
import Progress from "./Progress";
import ProgressEnd from "./ProgressEnd";
import Calendar from "./calendar/Calendar";
import SortSideMenu from "./calendar/SideSortMenu";
import SortStoreListModal from "./calendar/SortStoreListModal";

import callApi from "../mixins/callApi";
import { LOCAL_STORAGE_INFO, DOMAIN } from "@/const";
export default {
  components: {
    Calendar,
    Doc,
    Progress,
    ProgressEnd,
    SortSideMenu,
    SortStoreListModal,
  },
  mixins: [callApi],
  data() {
    return {
      isSideShow: true,
      isStoreListSortModalShow: false,
      today: moment(),
      beforeWeekDate: moment(this.today).add(-7, "days"),
      yesterday: moment(this.today).add(-1, "days"),
      inWeekDate: moment(this.today).add(7, "days"),
      // API返り値
      generateHome: {
        todayIssueInfo: {
          count: 0,
          alertTypeId: 0
        },
        progressCheckCount: 0,
        todayCompleteCount: 0,
        fixCheckCount: 0,
        finalDisposalSiteNGCount: 0,
        manifestAlertInfo: {
          count: 0,
          alertTypeId: 0
        },
        entrustAlertInfo: {
          count: 0,
          alertTypeId: 0
        },
        licenceAlertInfo: {
          count: 0,
          alertTypeId: 0
        }
      },
      useStoreList: [],
      storeList: [],
      selectedStoreList: [],
      domain: DOMAIN,
    };
  },

  methods: {
    linkTo() {
      this.$router.push({name:'manifests/all', params: { status: 10, isAtvie: true } });
    },
    toggleSideHandler() {
      this.isSideShow = !this.isSideShow;
    },

    // 事業場選択肢 ５件取得 ※Cookieに保存されていたらそれを使用
    getUseStoreList() {
      if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST]) {
        this.useStoreList = JSON.parse(
          localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST]
        );
      } else {
        this.storeList.forEach((list) => {
          list.generateStoreList.forEach((item) => {
            if (this.useStoreList.length < 5) {
              this.useStoreList.push(item);
            }
          });
        });
      }
    },
    // 事業場選択肢取得を選択状態にするため、
    initSelectedStoreList() {
      if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
        this.selectedStoreList = JSON.parse(
            localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]
        );
      } else {
        this.useStoreList.map((item) => {
          this.selectedStoreList = [...this.selectedStoreList, item.id];
        });
      }
    },
  },
  mounted() {
    this.getGenerateHomeApi();
    // 事業場選択肢取得
    this.getStoreListApi()
      .then(() => {
        this.getUseStoreList();
      })
      .then(() => {
        this.initSelectedStoreList();
      });
  },
};
</script>
