<template>
  <div class="l-wrapper loggedin">
    <div v-if="isInfosShow && infos.length" class="l-full-white info l-box">
      <Info
        infoType="static"
        v-for="info in infos"
        :info="info"
        :key="info.id"
      />
      <div class="l-full-white__close" @click="closeInfosHandler"></div>
    </div>
    <AppHeader />
    <AppSideMenu />
    <main class="l-main">
      <!--カレンダーの形を整える-->
      <!--CBAホーム-->
      <ContentsCba v-if="isCba" />
      <!--排出業者ホーム-->
      <ContentsGenerator v-else-if="isGenerator" />
      <!--運搬業者ホーム-->
      <ContentsCollector v-else-if="isCollector" />
      <!--処分代理ホーム-->
      <ContentsDisposer v-else-if="isDisposer" />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import Info from "@/components/parts/Info";
import { API_URL } from "@/const";
import ContentsCba from "./components/ContentsCba.vue";
import ContentsGenerator from "./components/ContentsGenerator.vue";
import ContentsCollector from "./components/Collector/ContentsCollector.vue";
import ContentsDisposer from "./components/ContentsDisposer.vue";

import callApi from "./mixins/callApi";
export default {
  name: "home",
  components: {
    Info,
    ContentsCba,
    ContentsGenerator,
    ContentsCollector,
    ContentsDisposer,
  },
  mixins: [callApi],
  data() {
    return {
      isInfosShow: true,
      infos: [],
      profile: {},
    };
  },
  computed: {
    isCba() {
      return this.$store.getters["userInfo/isCba"];
    },
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
  },
  methods: {
    closeInfosHandler() {
      this.isInfosShow = false;
    },

    // お知らせ取得
    async getInfos() {
      await axios
        .get(`${API_URL.INFO.ALL}?isOnlyImportant=${true}&isRead=${false}&page=${1}&pageSize=${10}`)
        .then((res) => {
          this.infos = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getInfos();
    this.getProfileApi();
  },
};
</script>
