<template>
  <div class="c-calendarSide__nopadding">
    <div class="c-calendarSide__inner">
      <div class="c-calendarSide__lead">
        <h5 class="c-lead-s">絞り込み</h5>
      </div>
      <div class="c-calendarSide__each">
        <h6 class="c-calendarSide__each__lead">
          <span
            class="c-calendarSide__each__lead__icon"
            @click="clickStoreListSortShowHandler"
          ></span>
          <span>事業場</span>
        </h6>
        <div class="c-calendarSide__each__items">
          <template v-for="(item, index) in useStoreList">
            <div
              class="c-checkbox red"
              :class="{
                red: index === 0,
                green: index === 1,
                skyBlue: index === 2,
                orange: index === 3,
                purple: index === 4,
              }"
              :key="item.id"
            >
              <input
                :id="'store-cb-' + item.id"
                type="checkbox"
                :value="item.id"
                v-model="selectedStoreListData"
              />
              <label class="c-checkbox__label" :for="'store-cb-' + item.id">
                <span class="c-checkbox__box"></span>
                {{ dottedText(item.name, 8) }}
              </label>
            </div>
          </template>
          <div
            class="c-toggle__home"
            :class="{
              isOpen: isSideShow,
            }"
          >
            <div class="c-toggle__btn" @click="showSideMenuhandler()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixin/common";
import { LOCAL_STORAGE_INFO } from "@/const";

export default {
  props: {
    useStoreList: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
    isStoreListSortModalShow: {
      type: Boolean,
    },
    isSideShow: {
      type: Boolean,
    },
  },
  mixins: [common],
  data() {
    return {
      selectedStoreListData: [],
    };
  },
  watch: {
    selectedStoreListData() {
      this.$emit("update:selectedStoreList", this.selectedStoreListData);
      localStorage.setItem(
        LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK, 
        JSON.stringify(this.selectedStoreListData)
      );
    },
    useStoreList() {
      if (!localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
          this.selectedStoreListData = this.useStoreList.map(uSl => {
          return uSl.id
        })
      }
    },
  },
  methods: {
    clickStoreListSortShowHandler() {
      this.$emit("update:isStoreListSortModalShow", true);
    },
    showSideMenuhandler() {
      this.isSideShow = !this.isSideShow;
      this.$emit("update:isSideShow", this.isSideShow);
    },
  },

  mounted() {
    // チェック状況を取得
    if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
        this.selectedStoreListData = JSON.parse(
        localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]
      );
    }
  },
};
</script>
