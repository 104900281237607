<template>
  <div class="c-calendar">
    <CalendarMonth
      :reserves="reserves"
      :currentDate="currentDate"
      :reserveClick="clickScheduleHandler"
      :useStoreList="useStoreList"
      :selectedStoreList="selectedStoreList"
    />
    <!-- 設定モーダル -->
    <SettingModal
      :isShow.sync="isSettingShow"
      :isPerHour="isPerHour"
      :startHourPos="startHourPos"
    />
    <!-- 詳細モーダル -->
    <DetailModal
      :schedule.sync="selectedSchedule"
      :isShow.sync="isDetailModalShow"
      :isDeleteModalShow.sync="isDeleteModalShow"
    />
    <!-- 削除確認モーダル -->
    <DeleteConfirmModal
      :isShow.sync="isDeleteModalShow"
      :isDetailModalShow.sync="isDetailModalShow"
      @delete="clickDeleteHandler"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CalendarMonth from "./CalendarMonth";
import SettingModal from "./SettingModal";
import DetailModal from "./DetailModal";
import callApi from "./mixins/callApi";
import DeleteConfirmModal from "./DeleteConfirmModal";
import detailHandler from "./mixins/detailHandler";
import generateCalendar from "./mixins/generateCalendar";
import {API_URL} from "@/const";

export default {
  components: {
    CalendarMonth,
    SettingModal,
    DetailModal,
    DeleteConfirmModal,
  },
  mixins: [callApi, detailHandler, generateCalendar],
  props: {
    useStoreList: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
  },

  data() {
    return {
      schedules: [],
      isSettingShow: false,
      currentDate: moment(),
      reserves: {},
      isDetailModalShow: false,
      isDeleteModalShow: false,
      selectedSchedule: null,
      isPerHour: true,
      startHourPos: 0,
    };
  },
  watch: {
    schedules: function () {
      this.formatReserves();
    },
  },
  computed: {
    getSchedules() {
      return this.schedules;
    },
  },
  methods: {
    getSchedulesForTwoWeeks() {
      this.schedules = [];
      const lastTwoWeeks = moment(this.currentDate).subtract(6, 'days');
      const nextTwoWeeks = moment(this.currentDate).add(13, 'days');

      if (lastTwoWeeks.month() !== this.currentDate.month()) {
        this.getScheduleListApi(lastTwoWeeks);
        this.getScheduleListApi(this.currentDate);
      } else if (nextTwoWeeks.month() !== this.currentDate.month()) {
        this.getScheduleListApi(this.currentDate);
        this.getScheduleListApi(nextTwoWeeks);
      } else {
        this.getScheduleListApi(this.currentDate);
      }
    },

    // Calendarに表示しやすいようにデータフォーマットする
    formatReserves() {
      this.reserves = {};
      this.schedules.map((schedule) => {
        schedule.scheduleInfoList.map((info) => {
          let newInfo = info;
          newInfo.generateStoreId = schedule.generateStoreId;
          const formatteddate = moment(info.collectionDate, "YYYYMMDD").format(
            "YYYY-MM-DD"
          );
          if (!this.reserves[formatteddate]) {
            this.reserves[formatteddate] = [newInfo];
          } else {
            this.reserves[formatteddate] = [
              ...this.reserves[formatteddate],
              newInfo,
            ];
          }
        });
      });
    },

    // スケジュール押下後にAPI呼び出して詳細モーダル展開  API_URL.
    clickScheduleHandler(manifestId) {
      axios
        .get(API_URL.SCHEDULE.DETAIL + manifestId)
        .then((res) => {
          this.selectedSchedule = res.data;
        })
        .then(() => {
          this.isDetailModalShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    // スケジュール一覧
    this.getSchedulesForTwoWeeks();
    this.formatReserves();
  },
};
</script>
