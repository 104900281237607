<template>
  <div class="c-progress">
    <router-link :to="titleLinkTo">
      <span class="c-progress__title">{{ title }}</span>
    </router-link>
    <router-link 
      :to="linkTo" 
      class="c-progress__arrow"
      :class="[
        {
          warning: isWarning,
          alert: isAlert,
        },
        progressType,
      ]"
    >
      <span class="c-progress__arrow__icon" :class="progressType"></span>
      <!-- カウントが４桁以上でアラートアイコン表示ありの場合、位置調整CSSを適用 -->
      <div class="c-progress__arrow__inner" :class="{
        adjustinner: count.toString().length >=4 && (isWarning || isAlert),
      }">
        <span class="c-progress__arrow__str">{{ progressStr }}</span>
        <span class="c-progress__arrow__count">{{ count }}</span>
      </div>
      <span class="c-progress__arrow__arrow"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    progressType: {
      type: String,
    },
    title: {
      type: String,
    },
    progressStr: {
      type: String,
    },
    count: {
      type: Number,
    },
    isWarning: {
      type: Boolean,
    },
    isAlert: {
      type: Boolean,
    },
    titleLinkTo: {
      type: String,
      default: "/",
    },
    linkTo: {
      type: String,
      default: "/",
    },
  },
};
</script>
