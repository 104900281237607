import {sortBy} from "lodash";
import moment from "moment";
export default {
  data() {
    return {};
  },
  methods: {
    getShowedReserves(reserves) {
      const showedItems = reserves.filter((reserve) => {
        // 選択した排出事業場に紐づくマニフェスト && ルート設定有りのマニフェスト
        return (
          this.selectedStoreList.indexOf(reserve.generateStoreId) >= 0 &&
          reserve.jwnetItemTypeId
        )
      });
      return this.sortReserves(showedItems);
    },
    sortReserves(reserves) {
      return sortBy(reserves, [function(item) { return moment(item.collectionTime, 'HH:mm').format('HH:mm')}]);
    }
  },
};
