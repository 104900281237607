import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      collectHome: {},
    };
  },
  methods: {
    // プロフィール情報取得API    API_URL.PROFILE.PROFILE
    getProfileApi() {
      return axios
      .get(API_URL.PROFILE.PROFILE)
        .then((res) => {
          this.profile = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬業者ホーム情報取得API    API_URL.HOME.COLLECTHOME
    getCollectHomeApi() {
      return axios
        .get(API_URL.HOME.COLLECTHOME)
        .then((res) => {
          this.collectHome = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類別数量集計取得API    API_URL.TOTALIZATION.JWNET_ITEM
    // type = {month/week}
    getTotalizationJwnetItemApi(type) {
      return axios
        .get(`${API_URL.TOTALIZATION.JWNET_ITEM}?type=${type}`)
        .then((res) => {
          this.totalizationJwnetItem = res.data.totalizationJwnetItemList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Wellfest利用件数取得    API_URL.TOTALIZATION.REGIST_COUNT
    getTotalizationRegistCountApi() {
      return axios
        .get(API_URL.TOTALIZATION.REGIST_COUNT)
        .then((res) => {
          this.totalizationRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 事業場選択肢取得API
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          this.storeList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 初期で呼ぶAPIまとめ
    getInitApi() {
      // 廃棄物種類別数量集計取得API
      this.getTotalizationJwnetItemApi("month");

      // Wellfest利用件数取得
      this.getTotalizationRegistCountApi();
    },
  },
};
